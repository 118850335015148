import React, { useMemo, useState } from "react"
import "./App.css"
import lettersRaw from "./data/letters.json"
import urdu1000 from "./data/urdu1000.json"
import _ from "lodash"

const urduLetters = Object.fromEntries(lettersRaw.urdu.map((x) => [x.character, x.name]))
const level = 6
const levelMsg = [
  "Hello world!",
  "You passed level 1 with flying color! Here's level two! 4 new letters. س ب ت د",
  "It was a breeze for you so far! Let's see if level 3 will give you a challenge. A lot of triple dot letters!! گ چ ج ش پ",
  "This is when things get Urdu!! Welcome to level 4",
  "A perfect score so far! Level 5 is here. ص=س ح=ه 🤯",
  "Level 6! ں ڈ ز ڑ ع closing in on the end!",
  "7",
  "8",
][level - 1]

function App() {
  const words = useMemo(() => {
    const levels = [
      ["ا", "ر", "ل", "م", "ن", "و", "ک", "ی"],
      ["س", "ب", "ت", "د"],
      ["پ", "ج", "ش", "چ", "گ"],
      ["ه", "ٹ", "ھ", "ے"],
      ["ف", "ق", "ص", "خ", "ح"],
      ["ں", "ڈ", "ز", "ڑ", "ع"],
      ["غ", "ض", "ط", "ئ", "آ"],
      ["ژ", "ذ", "ث", "ؤ", "ظ"],
    ]
    const validLetters = _.flatten(levels.slice(0, level))

    const filtered = urdu1000
      .filter((x) => !x.urdu.includes(" "))
      .filter((x) => [...x.urdu].length <= 6)
      .filter((x) => _.union(validLetters, [...x.urdu]).length <= validLetters.length)
    return _.shuffle(filtered)
  }, [])
  const [idx, setIdx] = useState(0)
  const word = words[idx]
  const [difficulty, setDifficulty] = useState(0)

  useMemo(() => {
    // Just to debug, finds the most common letters
    const countDict: Record<string, number> = {}
    words.forEach((w) => [...w.urdu].forEach((l) => (countDict[l] = (countDict[l] || 0) + 1)))

    let counts = Object.entries(countDict)
    counts = _.sortBy(counts, ([letter, count]) => count)
    _.reverse(counts)
    counts.forEach(([letter, count]) => console.log(count, letter))
  }, [words])

  function next() {
    setIdx((idx + 1) % words.length)
  }

  function prev() {
    setIdx((idx - 1 + words.length) % words.length)
  }

  function renderDifficulty(level: number, label: string) {
    return (
      <li>
        <label onClick={(_) => setDifficulty(level)}>
          <input type="radio" checked={difficulty === level} /> {label}
        </label>
      </li>
    )
  }

  return (
    <div className="App">
      <div className="WordCard">
        <div className="word">{word.urdu}</div>
        {difficulty < 3 && (
          <div className="letters">
            {[...word.urdu].map((x) => (
              <div className="letter">
                {difficulty < 2 && <div className="latin">{urduLetters[x]}</div>}
                <div className="arabic">{x}</div>
              </div>
            ))}
          </div>
        )}

        <button onClick={prev} className="next">
          Previous
        </button>
        <button onClick={next} className="next">
          Next
        </button>

        <pre>
          <br />
          {idx}/{words.length}
          <br />
          {difficulty < 1 && <h4>{word.english}</h4>}
        </pre>
      </div>
      <hr />
      <br />
      <div>{levelMsg}</div>
      <div className="difficulty">
        <h3>Difficulty</h3>
        <ul>
          {renderDifficulty(0, "Learner 🥹")}
          {renderDifficulty(1, "No translation 💪")}
          {renderDifficulty(2, "No latin letters ✉️")}
          {renderDifficulty(3, "I'm boss 🔪")}
        </ul>
      </div>
    </div>
  )
}

export default App
